import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Container, deviceMin, deviceMax } from "../components/Primitives"
import Header from "./header"
import "./layout.css"
import "../../static/styles/styles.scss"
import fb from "../../static/Icons/facebook-512.webp"
import linkedin from "../../static/Icons/li.png"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import "./footer.css"

const Footer = () => {
  return (
    <div>
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h6>About</h6>
              <p className="text-justify">
                We are committed to meeting the needs of our clients in a timely
                and cost effective manner. Our goal is to offer our clients the
                option that best suits their needs, be it a short or long term
                assignment, or on a project or ad hoc basis and our research
                effectiveness, innovation, flexibility and professionalism make
                us a powerful and effective resource for your organization
              </p>
              <p className='font-weight-bold'>
                Email: <a href="mailto:career.ladder.recruitment@gmail.com">career.ladder.recruitment@gmail.com</a>
                <br />
                 Phone: <a href="tel:923177116157">+92 317 7116157</a>
              </p>
            </div>

            <div style={{left: '10%'}} className="col-xs-6 col-md-3">
              <h6>Quick Links</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/">About Us</Link>
                </li>
                <li>
                  <Link to="/our-services">Services</Link>
                </li>
                <li>
                  <Link to="/apply-jobs">Apply for jobs</Link>
                </li>
                <li>
                  <Link to="/trainers">Trainers</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-6 col-md-3">
              <ul className="social-icons">
                {/*<li>*/}
                {/*  <a className="facebook" href="#">*/}
                {/*    <i className="fa fa-facebook"/>*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <a className="twitter" href="#">*/}
                {/*    <i className="fa fa-twitter"/>*/}
                {/*  </a>*/}
                {/*</li>*/}
                <li>
                  <a
                    className="dribbble"
                    href="https://www.facebook.com/Career-Ladder-102297011554925"
                    target="blank"
                  >
                    {/*{<i className="fa fa-dribbble"/>}*/}
                    {<img src={fb} />}
                  </a>
                </li>
                <li>
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/in/career-ladder-48749695/"
                    target="blank"
                  >
                    <i className="fa fa-linkedin" />
                    <img src={linkedin} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-6 col-xs-12">
              <p className="copyright-text">
                Copyright &copy; 2020 All Rights Reserved by
                <Link to="/"> Career Ladder</Link>.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer

const FooterContainer = styled(Container)`
  padding: 20px;
  background-color: var(--grey);
  max-width: none;

  svg {
    margin-right: 20px;
    fill: var(--green);
    box-shadow: var(--boxShadow);
    border-radius: 50%;
    transition: all 0.25s linear;

    &:hover {
      box-shadow: var(--boxShadowHover);
      transform: var(--transform);
    }
  }

  @media ${deviceMax.mobileL} {
    width: 100%;
    text-align: center;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 2rem auto;
  max-width: 1440px;
`
const Card = styled.div`
  box-sizing: border-box;
  text-decoration: none;
  margin-bottom: 50px;
  padding: 0 20px;
  justify-content: space-between;

  @media ${deviceMin.mobileS} {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media ${deviceMin.tablet} {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media ${deviceMin.laptop} {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`
const Logo = styled(Link)`
  color: var(--text);
  text-transform: none;
`
