import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Container, H2, UL, LI } from "./Primitives"
import styled from "styled-components"
import "../../static/styles/styles.scss"

import careerLadder from "../../static/logos/cl-logo.png"
import { Collapse, Navbar, Nav, NavDropdown } from "react-bootstrap"
import "./header.css"
import { AnchorLink } from "gatsby-plugin-anchor-links/index"

const Header = ({ siteTitle }: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  return (
    // <nav
    //   className="navbar navbar-expand-lg navbar-light"
    //   style={{
    //     height: "120px",
    //     backgroundColor: "white"
    //   }}
    // >
    //   <a className="navbar-brand" href="/">
    //     <Logo src={careerLadder} width="200px" height="100px" />
    //   </a>
    //   <button
    //     className="navbar-toggler"
    //     type="button"
    //     data-toggle="collapse"
    //     data-target="#navbarSupportedContent"
    //     aria-controls="navbarSupportedContent"
    //     aria-expanded="false"
    //     aria-label="Toggle navigation"
    //   >
    //     <span className="navbar-toggler-icon"></span>
    //   </button>

    //   <div
    //     className="collapse navbar-collapse"
    //     id="navbarSupportedContent"
    //     style={{ zIndex: 1, backgroundColor: "green" }}
    //   >
    //     <ul className="navbar-nav ml-auto">
    //       <li className="nav-item active">
    //         <a className="nav-link" href="/#about">
    //           About <span className="sr-only">(current)</span>
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" href="/our-services">
    //           Services
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" href="/apply-jobs">
    //           Apply for jobs
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" href="/trianers">
    //           Trainers
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" href="/contact">
    //           Contact
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    // </nav>

    // <nav
    //   className="navbar navbar-expand-lg navbar-light"
    //   style={{
    //     backgroundColor: "white"
    //   }}
    // >
    //   <a className="navbar-brand" href="/">
    //     <Logo
    //       src={careerLadder}
    //       width="auto"
    //       height="60px"
    //       // style={{
    //       //   padding: "20px"
    //       // }}
    //     />
    //   </a>
    //   <button
    //     className="navbar-toggler"
    //     type="button"
    //     data-toggle="collapse"
    //     data-target="#navbarNav"
    //     aria-controls="navbarNav"
    //     aria-expanded="false"
    //     aria-label="Toggle navigation"
    //   >
    //     <span className="navbar-toggler-icon"></span>
    //   </button>
    //   <div className="collapse navbar-collapse" id="navbarNav">
    //     <ul className="navbar-nav" style={{ marginLeft: "auto" }}>
    //       <li className="nav-item active">
    //         <a className="nav-link" href="/#about">
    //           About <span className="sr-only">(current)</span>
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" href="/our-services">
    //           Services
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" href="/apply-jobs">
    //           Apply for jobs
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" href="/trianers">
    //           Trainers
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" href="/contact">
    //           Contact
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    // </nav>
    <header>
        <Link className="navbar-brand" to="/">
          <Logo
            src={careerLadder}
            width="auto"
            height="80px"
            style={{ paddingLeft: "20px", paddingBottom: "10px" }}
          />
        </Link>
        <input type="checkbox" id="nav-toggle" className="nav-toggle" />
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <AnchorLink to={"/#about"}>About</AnchorLink>
            </li>
            <li>
              <Link to="/our-services">Services</Link>
            </li>
            <li>
              <Link to="/apply-jobs">Apply For Jobs</Link>
            </li>
            <li>
              <Link to="/trainers">Trainers</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
        <label htmlFor="nav-toggle" className="nav-toggle-label">
          <span/>
        </label>
    </header>
  )
}

export default Header

const Logo = styled.img`
  margin: 0px;
  @media (max-width: 992px) {
    margin-top: -3px;
  }
`
